<template>
    <div class="border border-gray-100 rounded w-full relative mb-3">
        <div class="overflow-x-auto overflow-y-auto h-[500px]">
            <table class="myTable">
                <thead class="text-left">
                    <tr>
                        <th>內容</th>
                        <th>客服人員</th>
                        <th>註記時間</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index">
                        <td>{{ item.note }}</td>
                        <td>{{ item.administrator.name }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datas: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>

<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-3">
                    <label class="text-gray-400">
                        訂單編號
                        <el-input v-model="filterOpts.order_id" size="small" clearable> </el-input>
                    </label>
                </div>
                <div class="col-span-4">
                    <label class="text-gray-400">
                        開始日期 範圍
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        狀態
                        <el-select v-model="filterOpts.status" size="small" class="w-full" clearable>
                            <el-option v-for="(status, index) in payStatusOptions" :key="index" :label="status.label" :value="status.value">
                                {{ status.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>
                <div class="col-span-2 flex items-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <!-- <thead>
                    <tr>
                        <th>狀態</th>
                        <th>會員暱稱</th>
                        <th>活動項目</th>
                        <th>訂單總額</th>
                        <th>成立時間</th>
                        <th>開始時間</th>
                        <th>查看</th>
                    </tr>
                </thead> -->
                <TableHead ref="TableHead" :labels="tableLabels" @changeSort="changeSort" />
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                        <td>{{ item.status | dateStatus }}</td>
                        <td>
                            {{ isProvider ? item.user.name : item.provider.name }}
                        </td>
                        <td>{{ item.category.name }}</td>
                        <td>${{ getPriceByItem(item).price | formatCurrency }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                        <td>{{ item.started_at | formatDateTime }}</td>
                        <td class="cursor-pointer" @click="goEdit(item.id)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 對帳相關 configs
import orderConfig from "@/config/orderConfig.js";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// order filters
import "@/views/user/orders/filter";
// init search Filter options
const defaultFilterOpts = {
    order_id: "",
    status: "",
    started_at_start: "",
    started_at_end: "",
    sort_by: "", // created_at or started_at or gross_price
    sort_type: "", // asc or desc
};
export default {
    name: "OrderList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
    },
    props: {
        // 當前 user 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }

            return {
                ...filter,
                role: this.isProvider ? 1 : 0,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 付款狀態 options
        payStatusOptions() {
            const status = orderConfig.orderStatus;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            id: "", // 服務商id
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts }, // 篩選options
            dateInputs: [], // 時間範圍
            // table header
            tableLabels: [
                {
                    label: "狀態",
                },
                {
                    label: this.isProvider ? "會員暱稱" : "服務商暱稱",
                },
                {
                    label: "活動項目",
                },
                {
                    label: "訂單總額",
                    val: "gross_price",
                },
                {
                    label: "成立時間",
                    isSort: true,
                    val: "created_at",
                },
                {
                    label: "開始時間",
                    isSort: true,
                    val: "started_at",
                },
                {
                    label: "查看",
                },
            ],
        };
    },
    methods: {
        // 預設
        setDefault(val) {
            this.id = val.id;
            this.getList();
        },
        // 讀取 list api
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchDatings(this.id, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 改變排序
        changeSort(val) {
            this.filterOpts.sort_by = val.sortBy;
            this.filterOpts.sort_type = val.orderBy;
            this.getList();
        },
        // 重置
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.$refs.TableHead.reset();
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 到訂單細節頁,將此頁資訊帶入
        goEdit(id) {
            const userID = this.$route.params.id;
            const tab = this.isProvider ? "provider_update" : "member_update";
            const pre = "OrderList";
            this.$router.push({
                name: "order_detail",
                params: { tab, pre, orderID: id, userID },
            });
        },
        // 顯示金額需額外加上需約單統計
        getPriceByItem(info) {
            let price = 0;
            let fee = 0;
            let price2 = 0;
            let fee2 = 0;
            info.dating_extensions.forEach((i) => {
                // 只取完成的續約單統計
                if (i.status === 2) {
                    price += i.details.price;
                    fee += i.details.fee;
                }
                // 未確認
                if (i.status === 1) {
                    price2 += i.details.price;
                    fee2 += i.details.fee;
                }
            });

            const totalCount = price + price2 + info.details.tip + info.details.serviceCharge;
            const serviceCharge = info.details.fee + fee + fee2;

            return {
                price: totalCount + serviceCharge,
            };
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.started_at_start = "";
                this.filterOpts.started_at_end = "";
                return;
            }
            this.filterOpts.started_at_start = val[0];
            this.filterOpts.started_at_end = val[1];
        },
    },
    activated() {
        if (this.id) {
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>

<template>
    <Dialog :showDialog="showDialogs" :customClass="'position'">
        <div class="pop-width md:py-10 md:px-3 px-3 py-5 bg-white rounded">
            <div class="close" @click="cancel">
                <i class="fas fa-times"></i>
            </div>
            <div class="content">
                <div class="title">
                    {{ param.title }}
                </div>
                <div class="des mb-3">{{ param.message }}</div>
                <div class="input-item">
                    <div class="label">客訴單編號</div>
                    <el-input v-model="input.orderId" placeholder="請輸入客訴單編號" size="small"> </el-input>
                </div>
                <div class="input-item">
                    <div class="label">{{ param.type === "add" ? "新增" : "扣除" }}餘額</div>
                    <el-input
                        v-model="input.amount"
                        type="number"
                        max="50000"
                        size="small"
                        :placeholder="param.type === 'add' ? '請輸入新增餘額' : '請輸入扣除餘額'">
                    </el-input>
                </div>
                <div class="input-item">
                    <!-- 新增或扣除點數原因 -->
                    <el-radio-group v-model="input.exchange">
                        <div class="mb-2">
                            <el-radio :label="1">{{ param.type === "add" ? "從平台帳戶扣除" : "新增至平台帳戶" }}</el-radio>
                        </div>
                        <div v-if="!isProvider">
                            <el-radio :label="0">直接{{ param.type === "add" ? "新增" : "扣除" }}</el-radio>
                        </div>
                    </el-radio-group>
                </div>
                <div v-if="errorMessage" class="errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <el-button :disabled="disable" class="orange-btn-800 md:text-sm text-xs w-[100px] h-[30px]" @click="confirm">
                    {{ param.type === "add" ? "新增" : "扣除" }}餘額
                </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

export default {
    name: "PopupUpdateBalance",
    components: {
        Dialog,
    },
    props: {
        // 當前 user 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
        param: {
            type: Object,
            default() {
                return {
                    title: "",
                    message: "",
                    type: "add",
                };
            },
        },
        value: {
            type: Boolean,
            require: true,
        },
        currentBalance: {
            type: Number,
            require: true,
        },
    },
    computed: {
        showDialogs: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    data() {
        return {
            disable: true,
            errorMessage: "",
            input: {
                orderId: "",
                amount: "",
                // 新增或扣除原因
                exchange: 1,
            },
        };
    },
    methods: {
        cancel() {
            this.showDialogs = false;
        },
        confirm() {
            const { orderId, amount, exchange } = this.input;
            const type = this.param.type;
            this.showDialogs = false;
            this.$emit("confirm", {
                orderId,
                amount: type === "add" ? amount : -amount,
                exchange,
            });
        },
    },
    watch: {
        input: {
            handler(val) {
                const { orderId, amount } = val;
                // 確認必填輸入
                this.errorMessage = "";
                if (orderId && amount) {
                    this.disable = false;
                } else {
                    this.disable = true;
                }
                if (this.param.type === "remove") {
                    // 扣除餘額限制
                    if (this.currentBalance < amount) {
                        this.disable = true;
                        this.errorMessage = "餘額不足";
                    }
                } else {
                    // 新增餘額限制
                    if (50000 < amount) {
                        this.disable = true;
                        this.errorMessage = "單筆最多新增 $50,000";
                    }
                }
            },
            deep: true,
        },
        value() {
            this.input = {
                orderId: "",
                amount: "",
                exchange: 1,
            };
        },
    },
};
</script>

<style lang="scss">
.el-radio__input.is-checked + .el-radio__label {
    @apply text-gray-700;
}
.pop-width {
    max-width: 350px;
    margin: 0 162px 0 0;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            font-size: 16px;
            font-weight: 500;
            margin-top: 10px;
        }
        .des {
            margin-top: 10px;
            font-size: 12px;
            color: #707070;
        }
    }
}

.input-item {
    width: 80%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
        width: 150px;
        color: #2c2c2c;
        font-size: 14px;
        margin-right: 10px;
        white-space: nowrap;
    }
}

.errorMessage {
    width: 80%;
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}

@media (min-width: 768px) {
    .position {
        top: calc(25% + 85px);
        left: calc(50% - 125px);
    }
}
</style>

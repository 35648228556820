<template>
    <div>
        <h2 class="font-medium md:text-2xl text-base md:mb-10 mb-3">已綁定之第三方帳號</h2>
        <ul class="mb-5">
            <li v-for="(item, key) in haveBindSocialMedia" :key="key" :class="item.index > 0 ? 'mt-2' : ''">
                <h5 class="text-gray-300 text-xs my-2 md:text-base">{{ key | showSocialMediaName }}</h5>
                <div class="flex items-center mb-5">
                    <div class="flex-1 flex items-center">
                        <Avatar :backgroundImg="item.picture" />
                        <h6 class="mx-3 md:text-lg">{{ item.name }}</h6>
                    </div>
                    <button
                        class="border-orange-600 border text-orange-600 px-3 py-0.5 rounded disabled:bg-gray-300 disabled:cursor-not-allowed text-sm"
                        @click.prevent="openDialog(key)">
                        解除綁定
                    </button>
                </div>
            </li>
            <li v-for="(item, key) in unBindSocialMedia" :key="key" :class="item.index > 0 ? 'mt-2' : ''">
                <h5 class="text-gray-300 text-xs my-2 md:text-base">{{ key | showSocialMediaName }}</h5>
                <div class="flex items-center mb-5">
                    <div class="flex-1 flex items-center">
                        <h6 class="mx-3 md:text-lg">未綁定</h6>
                    </div>
                </div>
            </li>
        </ul>
        <!-- 確認是否取消預訂彈窗 -->
        <my-dialog :showDialog="showDialog" :customClass="'top-1/4 z-[70]'" :customBlackBgClass="'z-[60]'" @onCloseDialog="closeDialog">
            <div class="w-full md:p-10 p-5 bg-white rounded-lg text-sm">
                <p>確認解除此第三方帳號之綁定嗎？</p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDialog">
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="unlinkSocialMedia(showSocialMediaName)">
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script>
// 導入頭像組件
import Avatar from "@/components/Avatar.vue";
// 導入自定義彈窗組件
import myDialog from "@/components/Dialog.vue";

export default {
    name: "UnBindSocialMedia",
    components: {
        Avatar,
        myDialog,
    },
    props: {
        // 已綁定第三方登入資料
        haveBindSocialMedia: {
            type: Object,
            default() {
                return {};
            },
        },
        unBindSocialMedia: {
            type: Object,
            default() {
                return {
                    facebook_user: {
                        name: "facebook user",
                        picture: "",
                    },
                    google_user: {
                        name: "google user",
                        picture: "",
                    },
                    line_user: {
                        name: "line user",
                        picture: "",
                    },
                };
            },
        },
    },
    filters: {
        showSocialMediaName(val) {
            switch (val) {
                case "facebook_user":
                    return "Facebook";
                case "google_user":
                    return "Google";
                case "line_user":
                    return "LINE";
                default:
                    break;
            }
        },
    },
    data() {
        return {
            // 判斷是否顯示取消綁定彈窗
            showDialog: false,
            // 解除綁定第三方
            showSocialMediaName: null,
            loading: false,
        };
    },
    methods: {
        /**
         * 開啟確認取消綁定彈窗
         * @param { type String } socialMedia 解除綁定第三方名稱
         */
        openDialog(socialMedia) {
            this.showDialog = true;
            this.showSocialMediaName = socialMedia;
        },
        // 關閉確認取消綁定彈窗
        closeDialog() {
            this.showDialog = false;
        },
        /**
         * 取消綁定第三方
         */
        async unlinkSocialMedia(socialMedia) {
            let platform = "";
            switch (socialMedia) {
                case "facebook_user":
                    platform = "facebook";
                    break;
                case "google_user":
                    platform = "google";
                    break;
                case "line_user":
                    platform = "line";
                    break;
                default:
                    break;
            }
            try {
                await this.$api.UnLinkSocialMediaApi(this.$route.params.id, platform);
                this.showDialog = false;
                this.$emit("callback");
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "解除綁定失敗",
                });
                return;
            }
        },
    },
};
</script>
